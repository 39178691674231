import React from 'react';
import { Button } from '../../styles/components';
import styled from 'styled-components';
import {useNavigate} from "react-router-dom";

export const CTASection = styled.section`
  padding: ${({ theme }) => theme.spacing[20]} ${({ theme }) => theme.spacing[4]};
`;

export const CTAContainer = styled.div`
  max-width: ${({ theme }) => theme.maxWidth.container};
  margin: 0 auto;
  background-color: ${({ theme }) => theme.colors.primary[500]};
  border-radius: ${({ theme }) => theme.borderRadius.xl};
  padding: ${({ theme }) => theme.spacing[12]};
  text-align: center;
`;

export const CTATitle = styled.h2`
  font-size: 1.875rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: ${({ theme }) => theme.spacing[4]};
`;

export const CTADescription = styled.p`
  color: ${({ theme }) => theme.colors.primary[100]};
  margin-bottom: ${({ theme }) => theme.spacing[8]};
  max-width: 42rem;
  margin-left: auto;
  margin-right: auto;
`;

export default function CTA() {
    const navigate = useNavigate();

    return (
        <CTASection>
          <CTAContainer>
            <CTATitle>Ready to Start Breaking Language Barriers?</CTATitle>
            <CTADescription>
              Join thousands of satisfied users who are already using SAI for their translation needs.
            </CTADescription>
            <Button variant="white" onClick={() => navigate('/login')}>Get Started Now</Button>
          </CTAContainer>
        </CTASection>
    );
}