import React from 'react';
import { Zap, Clock, Globe, Smartphone } from 'lucide-react';
import { Feature } from './types';

export const features: Feature[] = [
  {
    icon: <Zap size={32} />,
    title: 'Real-time Translation',
    description: 'Get instant translations with industry-leading accuracy powered by advanced AI.'
  },
  {
    icon: <Clock size={32} />,
    title: '24/7 Availability',
    description: 'Access our translation services anytime, anywhere, with consistent performance.'
  },
  {
    icon: <Globe size={32} />,
    title: 'Multiple Languages',
    description: 'Support for over 100 languages with context-aware translations.'
  },
  {
    icon: <Smartphone size={32} />,
    title: 'Cross-platform',
    description: 'Use our service on any device with our web and mobile applications.'
  }
];