import React from 'react';
import { Check } from 'lucide-react';
import { MaxWidthContainer, SectionTitle, SectionDescription, Button } from '../styles/components';
import styled, { css } from 'styled-components';
import { PricingTier } from '../types';

interface CardProps {
    $highlighted?: boolean;
}

export const PricingSection = styled.section`
  padding: ${({ theme }) => theme.spacing[20]} ${({ theme }) => theme.spacing[4]};
  background-color: ${({ theme }) => theme.colors.gray[100]};
`;

export const PricingGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${({ theme }) => theme.spacing[8]};
  margin: 0 auto;
  
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const PricingCardWrapper = styled.div<CardProps>`
  transition: transform 0.3s ease;
  
  ${({ $highlighted }) => !$highlighted && css`
    &:hover {
      transform: scale(1.05);
    }
  `}
  
  ${({ $highlighted }) => $highlighted && css`
    transform: scale(1.05);
  `}
`;

export const Card = styled.div<CardProps>`
  border-radius: ${({ theme }) => theme.borderRadius.xl};
  padding: ${({ theme }) => theme.spacing[8]};
  height: 100%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  ${({ $highlighted, theme }) => $highlighted ? css`
    background-color: ${theme.colors.primary[500]};
    color: ${theme.colors.white};
  ` : css`
    background-color: ${theme.colors.white};
  `}
`;

export const PlanName = styled.h3`
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacing[2]};
`;

export const PriceWrapper = styled.div`
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacing[4]};
`;

export const Price = styled.span`
  font-size: 2.25rem;
  font-weight: bold;
`;

export const PriceUnit = styled.span<CardProps>`
  color: ${({ $highlighted, theme }) =>
    $highlighted ? theme.colors.primary[100] : theme.colors.gray[500]};
`;

export const PlanDescription = styled.p<CardProps>`
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacing[6]};
  color: ${({ $highlighted, theme }) =>
    $highlighted ? theme.colors.primary[100] : theme.colors.gray[600]};
`;

export const FeatureList = styled.ul`
  margin-bottom: ${({ theme }) => theme.spacing[8]};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[4]};
`;

export const FeatureItem = styled.li`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[3]};
  
  svg {
    color: ${({ theme }) => theme.colors.primary[500]};
  }
`;

export const pricingPlans: PricingTier[] = [
    {
        name: 'Basic',
        price: 0,
        description: 'Perfect for personal use and trying out our service',
        features: [
            'Up to 1,000 words per month',
            '10 languages',
            'Basic AI translation',
            'Web interface access',
        ],
        buttonText: 'Start Free',
        highlighted: false,
    },
    {
        name: 'Professional',
        price: 29,
        description: 'Ideal for professionals and small businesses',
        features: [
            'Up to 50,000 words per month',
            '50 languages',
            'Advanced AI translation',
            'API access',
            'Priority support',
            'Custom glossary',
        ],
        buttonText: 'Start Pro Trial',
        highlighted: true,
    },
    {
        name: 'Enterprise',
        price: 99,
        description: 'For organizations with advanced needs',
        features: [
            'Unlimited words',
            '100+ languages',
            'Enterprise-grade AI',
            'Advanced API access',
            '24/7 dedicated support',
            'Custom glossary',
            'Team collaboration',
            'Advanced analytics',
        ],
        buttonText: 'Contact Sales',
        highlighted: false,
    },
];

export default function PricingPlans() {
    return (
        <PricingSection id="pricing">
            <MaxWidthContainer>
                <SectionTitle>Simple, Transparent Pricing</SectionTitle>
                <SectionDescription>
                    Choose the perfect plan for your needs. All plans include our core translation features.
                </SectionDescription>
                <PricingGrid>
                    {pricingPlans.map((plan) => (
                        <PricingCardWrapper key={plan.name} $highlighted={plan.highlighted}>
                            <Card $highlighted={plan.highlighted}>
                                <PlanName>{plan.name}</PlanName>
                                <PriceWrapper>
                                    <Price>${plan.price}</Price>
                                    <PriceUnit $highlighted={plan.highlighted}>/month</PriceUnit>
                                </PriceWrapper>
                                <PlanDescription $highlighted={plan.highlighted}>
                                    {plan.description}
                                </PlanDescription>
                                <FeatureList>
                                    {plan.features.map((feature) => (
                                        <FeatureItem key={feature}>
                                            <Check size={20} />
                                            <span>{feature}</span>
                                        </FeatureItem>
                                    ))}
                                </FeatureList>
                                <Button
                                    variant={plan.highlighted ? 'white' : 'primary'}
                                    fullWidth
                                >
                                    {plan.buttonText}
                                </Button>
                            </Card>
                        </PricingCardWrapper>
                    ))}
                </PricingGrid>
            </MaxWidthContainer>
        </PricingSection>
    );
}
