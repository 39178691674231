import React, {useState} from "react";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {QRCodeSVG} from "qrcode.react";
import styled from "styled-components";
import {Button, IconStart} from "../../base";
import QrCodeIcon from '@mui/icons-material/QrCode';
import {useSessionListenUrl} from "./hooks/useSessionListenUrl";

const StyledDialogTitle = styled(DialogTitle)`
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
`;

const StyledDialogContent = styled(DialogContent)`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const QRCodeButton = styled(Button)`
    text-transform: lowercase;
    background-color: #fff;
    color: #101010;
    border: 1px solid #101010;
`;

const CloseDialogButton = styled(Button)`
    text-transform: lowercase;
`;

export function RoomQRCode() {
    const [open, setOpen] = useState(false);
    const url = useSessionListenUrl();

    return (
        <>
            <QRCodeButton onClick={() => setOpen(true)}>
                <IconStart size="medium"><QrCodeIcon /></IconStart>
                <span>QR code</span>
            </QRCodeButton>
            <Dialog open={open} onClose={() => setOpen(false)} fullScreen>
                <StyledDialogTitle>Connect to the translation</StyledDialogTitle>
                <StyledDialogContent>
                    <QRCodeSVG value={url} size={700} />
                </StyledDialogContent>
                <DialogActions>
                    <CloseDialogButton color="primary" onClick={() => setOpen(false)}>
                        Close
                    </CloseDialogButton>
                </DialogActions>
            </Dialog>
        </>
    );
}
