import React from 'react';
import styled from 'styled-components';
import {LoginNavButton, NavButton} from "./nav-buttons";

const Container = styled.div`
    display: flex;
    align-items: center;
    padding: 8px 0;
    min-height: 40px;
    border-bottom: 1px solid #e7e7e7;
`;

const Title = styled.div`
    flex-grow: 1;
    display: flex;
    align-items: center;
    gap: 12px;
`;

export function Header() {
    return (
        <Container>
            <Title>SAI</Title>
            <NavButton to="/panel/sessions">Sessions</NavButton>
            <NavButton to="/panel/profile/details">Profile</NavButton>
            <LoginNavButton />
        </Container>
    );
}
