export enum EventType {
    OnMessage = 'onmessage',
}

type EventListener = (event: Event) => any;

export class WebSocketClient {
    private websocket: WebSocket | undefined;
    private eventListeners: Partial<Record<EventType, EventListener[]>> = {};

    addEventListener<T extends EventType>(type: T, listener: WebSocket[T]) {
        if (!this.eventListeners[type]) {
            this.eventListeners[type] = [];
        }

        this.eventListeners[type]!.push(listener as EventListener);
    }

    removeEventListener<T extends EventType>(type: T, listener: WebSocket[T]) {
        if (!this.eventListeners[type]) {
            return;
        }

        this.eventListeners[type] = this.eventListeners[type]!.filter(
            (existingListener) => existingListener !== listener,
        );
    }

    start(path: string) {
        return new Promise<void>((resolve, reject) => {
            const socket = new WebSocket(`${process.env.REACT_APP_WEBSOCKET_URL}${path}`);
            socket.binaryType = 'arraybuffer';
            socket.onmessage = (event: MessageEvent) => {
                this.eventListeners[EventType.OnMessage]?.forEach((listener) => listener(event));
            };

            socket.onopen = () => {
                this.close();
                this.websocket = socket;
                resolve();
            };

            socket.onerror = (error) => {
                reject(error);
            };
        });
    }

    send: WebSocket['send'] = (data) => {
        if (!this.websocket) {
            throw new Error('Websocket not started');
        }

        return this.websocket.send(data);
    };

    close() {
        if (this.websocket && this.websocket.readyState === WebSocket.OPEN) {
            this.websocket.close();
        }
    };
}
