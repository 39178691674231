import React from 'react';
import {Link} from 'react-router-dom';
import {useAsync} from 'react-use';
import {useApiClient} from "../../base/hooks/useApiClient";
import styled from "styled-components";
import {DataGrid, GridColDef, GridRowsProp} from '@mui/x-data-grid';
import {CreateSessionButton} from "./CreateSessionButton";

interface Session {
    id: number;
    name: string;
    createdAt: string;
}

const Actions = styled.div`
    display: flex;
    justify-content: end;
    margin-bottom: 16px;
`;

const StyledLink = styled(Link)`
    text-decoration: unset;
    color: unset;
    display: block;
    width: 100%;
    height: 100%;
`;

const StyledDataGrid = styled(DataGrid)`
    && {
        border: none;
        font-family: "Roboto Mono", monospace;

        .MuiDataGrid-columnHeaderTitle {
            font-weight: bold;
        }

        .MuiDataGrid-columnSeparator {
            display: none;
        }
    }
`;

export function SessionTablePage() {
    const apiClient = useApiClient();

    const { value: sessions } = useAsync(async () => {
        const response = await apiClient.get('sessions');

        if (!response.ok) {
            throw new Error('Cannot fetch list of sessions');
        }

        const data = await response.json() as { sessions: Session[] };

        return data.sessions;
    }, []);

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,
            renderCell: (params) => (
                <StyledLink to={`/panel/sessions/${params.value}`}>{params.value}</StyledLink>
            ),
        },
        {
            field: 'createdAt',
            headerName: 'Date',
            flex: 1,
            valueFormatter: (value: string) => new Date(value).toLocaleString(),
        },
    ];

    const rows: GridRowsProp = sessions?.map((session) => ({
        id: session.id,
        name: session.name,
        createdAt: session.createdAt,
    })) || [];

    return (
        <>
            <Actions>
                <CreateSessionButton />
            </Actions>
            <StyledDataGrid
                rows={rows}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: { pageSize: 15 },
                    },
                }}
                pagination
                disableColumnResize
            />
        </>
    );
}
