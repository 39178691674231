import React from 'react';
import styled from 'styled-components';
import { Outlet } from 'react-router-dom';
import {MenuItemConfig, SideMenu} from "./SideMenu";

const Container = styled.div`
    display: flex;
`;

const Content = styled.div`
    flex-grow: 1;
    padding: 0 16px;
`;

const menuItems: MenuItemConfig[] = [
    { label: 'Details', path: '/panel/profile/details' },
    { label: 'Billing', path: '/panel/profile/billing' },
];

export function ProfilePage() {
    return (
        <Container>
            <SideMenu items={menuItems} />
            <Content>
                <Outlet />
            </Content>
        </Container>
    );
}
