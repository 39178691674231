import React from "react";
import styled from "styled-components";
import {Header} from "./Header";
import {Outlet} from "react-router-dom";
import {Grid2} from "@mui/material";

const Container = styled(Grid2)`
    font-family: "Roboto Mono", monospace;
    font-weight: 400;
    font-style: normal;
    font-size: 12px;
    height: 100%;
    overflow: hidden;
    margin: 0 8px;
`;

const Top = styled(Grid2)`
    margin: 0 16px;
`;

const Content = styled(Grid2)`
    flex-grow: 1;
    flex-shrink: 1 !important;
    overflow: hidden;
    margin: 24px 16px;
    height: 50%;
`;

export function Page() {
    return (
        <Container container direction="column">
            <Top><Header /></Top>
            <Content>
                <Outlet />
            </Content>
        </Container>
    );
}
