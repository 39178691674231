import { colors } from './colors';
import { spacing } from './spacing';
import { breakpoints } from './breakpoints';
import { layout } from './layout';

export const theme = {
  colors,
  spacing,
  breakpoints,
  ...layout,
} as const;

export type Theme = typeof theme;