import {useAuth} from "../../../context";
import {Navigate} from "react-router-dom";
import {PropsWithChildren} from "react";

export function ProtectedRoute({ children }: PropsWithChildren) {
    const { isAuthenticated } = useAuth();

    if (!isAuthenticated) {
        return <Navigate to="/login" replace />;
    }

    return <>{children}</>;
}
