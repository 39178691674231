import React from 'react';

export function ProfileBillingPage() {
    return (
        <>
            <h1>Billing Page</h1>
            <p>Welcome to your profile!</p>
        </>
    );
}
