import { FooterCategory } from './types';

export const footerCategories: FooterCategory[] = [
  {
    title: 'Product',
    links: ['Features', 'Pricing', 'API'],
  },
  {
    title: 'Company',
    links: ['About', 'Blog', 'Careers'],
  },
  {
    title: 'Resources',
    links: ['Documentation', 'Support', 'Status'],
  },
  {
    title: 'Legal',
    links: ['Privacy', 'Terms', 'Security'],
  },
];