import React from 'react';
import { Feature } from './types';
import { Card, IconWrapper, Title, Description } from './styles';

interface FeatureCardProps {
  feature: Feature;
}

export function FeatureCard({ feature }: FeatureCardProps) {
  return (
    <Card>
      <IconWrapper>{feature.icon}</IconWrapper>
      <Title>{feature.title}</Title>
      <Description>{feature.description}</Description>
    </Card>
  );
}