import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Container, TextField, Typography, Box, Button } from '@mui/material';
import { useApiClient } from '../../base/hooks/useApiClient';
import styled from 'styled-components';

const StyledContainer = styled(Container)`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5rem;
`;

const StyledForm = styled.form`
    width: 300px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

const StyledButton = styled(Button)`
    && {
        background-color: #101010;
        color: #ececf1;
        border-radius: 1000px;
        padding: 8px 24px;
        min-height: 32px;
        transition: transform 0.1s ease-in-out, background-color 0.1s ease-in-out;
        &:hover {
            background-color: #404040;
        }
    }
`;

export function RegisterPage() {
    const [formError, setFormError] = useState<string | undefined>();
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const apiClient = useApiClient();

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        const response = await apiClient.post('register', { username, email, password });

        if (!response.ok) {
            const { error } = await response.json();
            setFormError(error);

            return;
        }

        navigate('/login');
    };

    return (
        <StyledContainer maxWidth="sm">
            <Box display="flex" flexDirection="column" alignItems="center" mt={5}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Register
                </Typography>
                <StyledForm onSubmit={handleSubmit}>
                    {formError && (
                        <Typography color="error" variant="body1" gutterBottom>
                            {formError}
                        </Typography>
                    )}
                    <TextField
                        label="Username"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                    />
                    <TextField
                        label="Email"
                        type="email"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <TextField
                        label="Password"
                        type="password"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    <StyledButton type="submit" variant="contained" fullWidth>
                        Register
                    </StyledButton>
                </StyledForm>
                <Typography variant="body2" mt={2}>
                    Have an account? <Link to="/login">Login here</Link>
                </Typography>
            </Box>
        </StyledContainer>
    );
}
