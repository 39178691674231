import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { EventType, WebSocketClient } from "../../../apis";

interface Props {
    websocket: WebSocketClient;
    propertyPath: string;
}

const Container = styled.div`
    flex-grow: 1;
    display: flex;
    position: relative;
    flex-direction: column;
    max-height: 100%;
    width: 100%;
    flex-shrink: 0;
    min-height: 0;
`;

const Title = styled.div`
    flex-shrink: 0;
    padding-top: 12px;
    padding-bottom: 8px;
    position: relative;
    font-size: 14px;
`;

const Content = styled.div`
    color: #101010;
    position: relative;
    flex-grow: 1;
    padding: 4px 0 8px;
    line-height: 1.2em;
    overflow: auto;
`;

const Text = styled.pre`
    display: block;
    white-space: pre-wrap;
    overflow: hidden;
`;

export function Stenographer({ websocket, propertyPath }: Props) {
    const [values, setValues] = useState<string[]>([]);

    useEffect(() => {
        const onMessage: WebSocket[EventType.OnMessage] = async (event) => {
            if (typeof event.data === 'string') {
                const data = JSON.parse(event.data);

                if (data[propertyPath]) {
                    setValues((prevTranslations) => [...prevTranslations, data[propertyPath]]);
                }
            }
        };

        websocket.addEventListener(EventType.OnMessage, onMessage);

        return () => {
            websocket.removeEventListener(EventType.OnMessage, onMessage);
        };
    }, []);

    return (
        <Container>
            <Title>{propertyPath}</Title>
            <Content>
                {values.length > 0 && values.map((value, index) => (
                    <Text key={index}>{value}</Text>
                ))}
                {values.length === 0 && `awaiting ${propertyPath}s...`}
            </Content>
        </Container>
    );
}
