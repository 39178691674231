import {ApiClient} from "./ApiClient";

export class SessionEventApi {
    constructor(private apiClient: ApiClient) {}

    public sessionStart(sessionUuid: string) {
        return this.apiClient.post('session-events', {
            sessionUuid,
            type: 'SESSION_START',
        });
    }

    public sessionEnd(sessionUuid: string) {
        return this.apiClient.post('session-events', {
            sessionUuid,
            type: 'SESSION_END',
        });
    }

    public listenerConnected(sessionUuid: string) {
        return this.apiClient.post('session-events', {
            sessionUuid,
            type: 'LISTENER_CONNECTED',
        });
    }

    public listenerDisconnected(sessionUuid: string) {
        return this.apiClient.post('session-events', {
            sessionUuid,
            type: 'LISTENER_DISCONNECTED',
        });
    }
}
