import React from 'react';
import styled from 'styled-components';

const StyledButton = styled.button`
  background-color: ${({ theme }) => theme.colors.primary[500]};
  color: ${({ theme }) => theme.colors.white};
  padding: ${({ theme }) => `${theme.spacing[4]} ${theme.spacing[8]}`};
  border-radius: ${({ theme }) => theme.borderRadius.full};
  font-size: 1.125rem;
  font-weight: 600;
  transition: all 0.2s;
  box-shadow: ${({ theme }) => theme.shadows.lg};

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary[600]};
    transform: translateY(-2px);
  }
`;

interface HeroButtonProps {
  children: React.ReactNode;
  onClick?: () => void;
}

export function HeroButton({ children, onClick }: HeroButtonProps) {
  return <StyledButton onClick={onClick}>{children}</StyledButton>;
}