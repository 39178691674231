import React from 'react';
import { footerCategories } from './data';
import {
  FooterContainer,
  FooterContent,
  FooterGrid,
  FooterColumn,
  ColumnTitle,
  LinkList,
  FooterLink,
  Copyright
} from './styles';

export default function Footer() {
  return (
    <FooterContainer>
      <FooterContent>
        <FooterGrid>
          {footerCategories.map((category) => (
            <FooterColumn key={category.title}>
              <ColumnTitle>{category.title}</ColumnTitle>
              <LinkList>
                {category.links.map((link) => (
                  <li key={link}>
                    <FooterLink href="#">{link}</FooterLink>
                  </li>
                ))}
              </LinkList>
            </FooterColumn>
          ))}
        </FooterGrid>
        <Copyright>
          <p>© {new Date().getFullYear()} SAI. All rights reserved.</p>
        </Copyright>
      </FooterContent>
    </FooterContainer>
  );
}