export const colors = {
  primary: {
    50: '#f5f5ff',
    100: '#ededff',
    200: '#dcdcff',
    300: '#c1c0ff',
    400: '#9d99ff',
    500: '#4f46e5', // Main primary color
    600: '#4338ca',
    700: '#3730a3',
    800: '#312e81',
    900: '#2c2879',
  },
  gray: {
    50: '#f9fafb',
    100: '#f3f4f6',
    200: '#e5e7eb',
    300: '#d1d5db',
    400: '#9ca3af',
    500: '#6b7280',
    600: '#4b5563',
    700: '#374151',
    800: '#1f2937',
    900: '#111827',
  },
  white: '#ffffff',
  black: '#000000',
} as const;