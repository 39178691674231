import React from 'react';
import { MaxWidthContainer } from '../../styles/components';
import { FeatureCard } from './FeatureCard';
import { features } from './data';
import { Section, Grid } from './styles';

export default function Features() {
  return (
    <Section>
      <MaxWidthContainer>
        <Grid>
          {features.map((feature) => (
            <FeatureCard key={feature.title} feature={feature} />
          ))}
        </Grid>
      </MaxWidthContainer>
    </Section>
  );
}