import React from 'react';

export function ProfileDetailsPage() {
    return (
        <>
            <h1>Details Page</h1>
            <p>Welcome to your profile!</p>
        </>
    );
}
