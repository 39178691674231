import React from 'react';
import { MaxWidthContainer } from '../../styles/components';
import { HeroButton } from './HeroButton';
import styled from 'styled-components';
import {useNavigate} from "react-router-dom";

export const HeroSection = styled.section`
  padding: ${({ theme }) => `${theme.spacing[32]} ${theme.spacing[4]} ${theme.spacing[20]}`};
  background: linear-gradient(
    to bottom right,
    ${({ theme }) => theme.colors.primary[50]},
    ${({ theme }) => theme.colors.primary[100]}
  );
`;

export const HeroContent = styled.div`
  text-align: center;
  max-width: ${({ theme }) => theme.maxWidth.content};
  margin: 0 auto;
`;

export const Title = styled.h1`
  font-size: 2.25rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.gray[900]};
  margin-bottom: ${({ theme }) => theme.spacing[6]};
  line-height: 1.2;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 3rem;
  }
`;

export const Highlight = styled.span`
  color: ${({ theme }) => theme.colors.primary[500]};
  font-weight: bold;
`;

export const Description = styled.p`
  font-size: 1.25rem;
  color: ${({ theme }) => theme.colors.gray[600]};
  margin-bottom: ${({ theme }) => theme.spacing[8]};
  max-width: 42rem;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.75;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export default function Hero() {
    const navigate = useNavigate();

    return (
        <HeroSection>
          <MaxWidthContainer>
            <HeroContent>
              <Title>
                Real-Time AI Translation <Highlight>for Everyone</Highlight>
              </Title>
              <Description>
                Break language barriers instantly with{' '}
                <Highlight>thesai.ai</Highlight>.
                Powered by advanced AI for accurate, natural translations in real-time.
              </Description>
              <ButtonContainer>
                <HeroButton onClick={() => navigate('/login')}>Start Translating Now</HeroButton>
              </ButtonContainer>
            </HeroContent>
          </MaxWidthContainer>
        </HeroSection>
    );
}