import {useEffect, useMemo} from "react";
import {EventType, Playback, WebSocketClient} from "../../../apis";

export function usePlayback(websocket: WebSocketClient) {
    const playback = useMemo(() => new Playback(), []);

    useEffect(() => {
        const onMessage: WebSocket['onmessage'] = async (event) => {
            if (event.data instanceof ArrayBuffer) {
                await playback.add(event.data);
            }
        };

        websocket.addEventListener(EventType.OnMessage, onMessage);

        return () => {
            websocket.removeEventListener(EventType.OnMessage, onMessage);
        };
    });

    return { playback };
}
