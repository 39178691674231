import {Link} from "react-router-dom";
import React from "react";
import {Breadcrumbs as MuiBreadcrumbs, Typography} from '@mui/material';
import styled from "styled-components";

interface Props {
    items: Breadcrumb[];
}

interface Breadcrumb {
    label: string;
    path?: string
}

const StyledBreadcrumbs = styled(MuiBreadcrumbs)`
    && {
        margin-bottom: 1rem;
    }

    & .MuiBreadcrumbs-li {
        display: flex;
    }
`;

const StyledLink = styled(Link)`
    text-decoration: none;
    color: #6e6e7f;
    font-weight: 500;
    font-size: 12px;
    
    &:hover {
        text-decoration: underline;
    }
`;

export function Breadcrumbs({ items }: Props) {
    return (
        <StyledBreadcrumbs aria-label="breadcrumb" separator="·">
            {items.map((item, index) => (
                item.path
                    ? <StyledLink key={index} to={item.path}>{item.label}</StyledLink>
                    : <Typography key={index} fontSize="12px" color="#6e6e7f">{item.label}</Typography>
            ))}
        </StyledBreadcrumbs>
    );
}
