import {MenuItem, MenuList} from "@mui/material";
import React from "react";
import styled from "styled-components";
import {useLocation, useNavigate} from "react-router-dom";

export interface MenuItemConfig {
    label: string;
    path: string;
}

interface Props {
    items: MenuItemConfig[];
}

const Container = styled.div`
    width: 11%;
    max-width: 150px;
    padding: 0 16px;
    border-right: 1px solid #e7e7e7;
`;

const StyledMenuItem = styled(MenuItem)<{ selected: boolean }>`
    && {
        font-size: 12px;
        border-radius: 1000px;
        display: flex;
        justify-content: center;
        background-color: ${({ selected }) => (selected ? '#e0e0e0' : 'inherit')};
    }
`;

export function SideMenu({ items }: Props) {
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <Container>
            <MenuList>
                {items.map((item) => (
                    <StyledMenuItem
                        key={item.path}
                        selected={location.pathname === item.path}
                        onClick={() => navigate(item.path)}
                    >
                        {item.label}
                    </StyledMenuItem>
                ))}
            </MenuList>
        </Container>
    );
}
