import React from "react";
import {useAuth} from "../../../../context";
import { NavButton } from "./NavButton";

export function LoginNavButton() {
    const { isAuthenticated, logout } = useAuth();

    function onClick() {
        if (isAuthenticated) {
            logout();
        }
    }

    return (
        <NavButton to="/login" onClick={onClick} forceShow>
            {isAuthenticated ? 'Logout' : 'Login'}
        </NavButton>
    );
}
