import React from 'react';
import { Globe2 } from 'lucide-react';
import styled from 'styled-components';
import { MaxWidthContainer } from '../styles/components';
import {useNavigate} from "react-router-dom";

const NavWrapper = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(8px);
  z-index: 50;
  box-shadow: ${({ theme }) => theme.shadows.sm};
`;

const NavContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4rem;
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[2]};
  color: ${({ theme }) => theme.colors.primary[500]};
`;

const LogoText = styled.span`
  font-size: 1.5rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.gray[900]};
`;

const AuthButtons = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[4]};
`;

const SignInButton = styled.button`
  color: ${({ theme }) => theme.colors.gray[700]};
  font-weight: 500;
  transition: color 0.2s;

  &:hover {
    color: ${({ theme }) => theme.colors.primary[500]};
  }
`;

const SignUpButton = styled.button`
  background-color: ${({ theme }) => theme.colors.primary[500]};
  color: ${({ theme }) => theme.colors.white};
  padding: ${({ theme }) => `${theme.spacing[2]} ${theme.spacing[4]}`};
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  font-weight: 500;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary[600]};
  }
`;

export default function Navbar() {
    const navigate = useNavigate();

    return (
        <NavWrapper>
            <MaxWidthContainer>
                <NavContent>
                    <Logo>
                        <Globe2 size={32} />
                        <LogoText>SAI</LogoText>
                    </Logo>
                    <AuthButtons>
                        <SignInButton onClick={() => navigate('/login')}>Sign In</SignInButton>
                        <SignUpButton onClick={() => navigate('/register')}>Sign Up</SignUpButton>
                    </AuthButtons>
                </NavContent>
            </MaxWidthContainer>
        </NavWrapper>
    );
}
