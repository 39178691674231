import React, { useState } from 'react';
import { Actions } from "../../layout";
import { Box, FormControl, InputLabel, MenuItem, Select, CircularProgress } from '@mui/material';
import { useAsync } from 'react-use';
import { useParams } from 'react-router-dom';
import { usePlayback, useWebsocket } from "../../base/hooks";
import { PlayPauseButton } from "../../base/PlayPauseButton";
import { useApiClient } from "../../base/hooks/useApiClient";
import { Stenographer } from "../../base";
import { useSessionEventApi } from "../../base/hooks/useSessionEventApi";
import {Grid2} from "@mui/material";

export function SessionListenPage() {
    const websocket = useWebsocket();
    const [language, setLanguage] = useState<string>('');
    const [isPlaying, setIsPlaying] = useState(false);
    const { sessionName } = useParams<{ sessionName: string }>();
    const apiClient = useApiClient();
    const { playback } = usePlayback(websocket);
    const sessionEventApi = useSessionEventApi();

    const { value: translationLanguages } = useAsync(async () => {
        const response = await apiClient.get(`sessions/${sessionName}/translation-languages`);

        if (!response.ok) {
            console.error('Error fetching session data');
        }

        const { translationLanguages } = await response.json();
        setLanguage(translationLanguages[0]);

        return translationLanguages as string[];
    });

    const { value: supportedLanguages } = useAsync(async () => {
        const response = await apiClient.get('supported-languages');

        if (!response.ok) {
            console.error('Error fetching session data');
        }

        const data = await response.json() as { supportedLanguages: Record<string, string> };

        return data.supportedLanguages;
    });

    const handleJoin = async () => {
        playback.init();
        await websocket.start(`/sessions/${sessionName}/${language}`);

        setIsPlaying(true);
        // await sessionEventApi.listenerConnected(sessionName!);
    };

    const onStopPlaying = async () => {
        websocket.close();

        setIsPlaying(false);
        // await sessionEventApi.listenerDisconnected(sessionName!);
    };

    const languagesLoaded = translationLanguages && supportedLanguages;

    return (
        <Grid2 container direction="column" sx={{ height: '100%' }}>
            <Grid2 size={{ xs: 12 }} sx={{ flexGrow: 1, height: '10%', overflow: 'auto' }}>
                <Stenographer websocket={websocket} propertyPath="translation" />
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
                <Actions>
                    <Box>
                        <FormControl variant="outlined">
                            {!languagesLoaded ? (
                                <CircularProgress size={24} style={{ minHeight: '56px' }} />
                            ) : (
                                <>
                                    <InputLabel id="language-select-label">Language</InputLabel>
                                    <Select
                                        labelId="language-select-label"
                                        variant="outlined"
                                        label="Language"
                                        value={language}
                                        disabled={isPlaying}
                                        onChange={(event) => setLanguage(event.target.value)}
                                    >
                                        {translationLanguages?.map((availableLanguage) => (
                                            <MenuItem key={availableLanguage} value={availableLanguage}>
                                                {supportedLanguages![availableLanguage]}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </>
                            )}
                        </FormControl>
                    </Box>

                    <PlayPauseButton
                        playText="start listening"
                        pauseText="stop"
                        onStart={handleJoin}
                        onStop={onStopPlaying}
                    />
                </Actions>
            </Grid2>
        </Grid2>
    );
}
