import styled, { css } from 'styled-components';

type ButtonVariant = 'primary' | 'secondary' | 'white';

interface ButtonProps {
  variant?: ButtonVariant;
  fullWidth?: boolean;
}

export const Button = styled.button<ButtonProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => `${theme.spacing[3]} ${theme.spacing[6]}`};
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  font-weight: 600;
  transition: all 0.2s ease-in-out;
  ${({ fullWidth }) => fullWidth && css`width: 100%;`}

  ${({ variant = 'primary', theme }) => {
    switch (variant) {
      case 'white':
        return css`
          background-color: ${theme.colors.white};
          color: ${theme.colors.primary[500]};
          &:hover {
            background-color: ${theme.colors.primary[50]};
          }
        `;
      case 'secondary':
        return css`
          background-color: transparent;
          color: ${theme.colors.gray[700]};
          &:hover {
            color: ${theme.colors.primary[500]};
          }
        `;
      default:
        return css`
          background-color: ${theme.colors.primary[500]};
          color: ${theme.colors.white};
          &:hover {
            background-color: ${theme.colors.primary[600]};
          }
        `;
    }
  }}
`;