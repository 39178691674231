import React from 'react';
import { ThemeProvider } from 'styled-components';
import { theme } from './styles/theme';
import { GlobalStyles } from './styles/GlobalStyles';
import styled from 'styled-components';

import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Features from './components/Features';
import PricingPlans from './components/PricingPlans';
import CTA from './components/CTA';
import Footer from './components/Footer';

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.gray[50]};
`;

export function HomePage() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Container>
        <Navbar />
        <Hero />
        <Features />
        <PricingPlans />
        <CTA />
        <Footer />
      </Container>
    </ThemeProvider>
  );
}
