import React from 'react';
import {PlayPauseButton} from "../../base/PlayPauseButton";
import {useWavRecorder} from "./hooks/useWavRecorder";
import {useWebsocket} from "../../base/hooks";
import {Stenographer} from "../../base";
import {Actions, Breadcrumbs} from "../../layout";
import {RoomQRCode} from "./RoomQRCode";
import {useParams} from 'react-router-dom';
import {useSessionEventApi} from "../../base/hooks/useSessionEventApi";
import {Box, Grid2} from "@mui/material";
import {CopySessionListenLinkButton} from "./CopyLinkButton";

export function SessionPage() {
    const { sessionName } = useParams<{ sessionName: string }>();
    const websocket = useWebsocket();
    const recorder = useWavRecorder();
    const sessionEventApi = useSessionEventApi();

    const onStartPlaying = async () => {
        await websocket.start(`/sessions/${sessionName}`);

        await recorder.start((data) => {
            websocket.send(data.mono);
        });

        // await sessionEventApi.sessionStart(sessionName!);
    };

    const onStopPlaying = async () => {
        await recorder.stop();
        websocket.close();

        // await sessionEventApi.sessionEnd(sessionName!);
    }

    return (
        <Grid2 container direction="column" sx={{ height: '100%' }}>
            <Grid2 size={{ xs: 12 }}>
                <Breadcrumbs items={[
                    { label: 'Sessions', path: '/panel/sessions' },
                    { label: sessionName! }
                ]} />
            </Grid2>
            <Grid2 size={{ xs: 12 }} sx={{ flexGrow: 1, height: '10%' }}>
                <Stenographer websocket={websocket} propertyPath="transcript" />
            </Grid2>
            <Grid2>
                <Actions>
                    <Box display="flex" gap="10px">
                        <RoomQRCode />
                        <CopySessionListenLinkButton />
                    </Box>
                    <PlayPauseButton
                        playText="start"
                        pauseText="stop"
                        onStart={onStartPlaying}
                        onStop={onStopPlaying}
                    />
                </Actions>
            </Grid2>
        </Grid2>
    );
}
