import { Button, IconStart } from "../../base";
import ContentCopy from "@mui/icons-material/ContentCopy";
import React, {useRef, useState } from "react";
import { useSessionListenUrl } from "./hooks/useSessionListenUrl";
import {Snackbar, Box} from "@mui/material";

export function CopySessionListenLinkButton() {
    const url = useSessionListenUrl();
    const [open, setOpen] = useState(false);
    const buttonRef = useRef<HTMLButtonElement>(null);

    const handleShareLink = () => {
        navigator.clipboard.writeText(url).then(() => {
            setOpen(true);
        });
    };

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    return (
        <Box position="relative">
            <Button ref={buttonRef} onClick={handleShareLink}>
                <IconStart size="medium"><ContentCopy /></IconStart>
                copy link
            </Button>
            <Snackbar
                message="Client link copied to clipboard!"
                open={open}
                autoHideDuration={1000}
                onClose={handleClose}
                sx={{
                    fontFamily: '"Roboto Mono", monospace',
                    position: 'absolute',
                    '&&': { bottom: (buttonRef.current?.offsetHeight ?? 0) + 5, left: 0 }
                }}
                ContentProps={{ sx: { '&&': {  borderRadius: '1000px', display: 'flex', minWidth: 'max-content' } } }}
            />
        </Box>
    );
}
