import styled from "styled-components";
import {Button} from "../../../base";
import {useNavigate} from "react-router-dom";
import {PropsWithChildren} from "react";
import {useAuth} from "../../../../context";

interface NavButtonProps {
    to: string;
    /**
     * Function to be called when the button is clicked before redirecting.
     */
    onClick?: () => void;
    /**
     * By default, the button will only be shown if the user is authenticated.
     * If true, the button will be shown even if the user is not authenticated.
     */
    forceShow?: boolean;
}

const StyledButton = styled(Button)`
    background-color: #fff;
`;

export function NavButton(props: PropsWithChildren<NavButtonProps>) {
    const { isAuthenticated } = useAuth();
    const { children, to, onClick, forceShow } = props;
    const navigate = useNavigate();

    function handleOnClick() {
        if (onClick) {
            onClick();
        }

        navigate(to);
    }

    if (!isAuthenticated && !forceShow) {
        return null;
    }

    return (
        <StyledButton onClick={handleOnClick}>
            {children}
        </StyledButton>
    );
}
