import styled from 'styled-components';

export const SectionTitle = styled.h2`
  font-size: 1.875rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacing[6]};
  color: ${({ theme }) => theme.colors.gray[900]};

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 2.25rem;
  }
`;

export const SectionDescription = styled.p`
  text-align: center;
  color: ${({ theme }) => theme.colors.gray[600]};
  font-size: 1.125rem;
  max-width: 42rem;
  margin: 0 auto ${({ theme }) => theme.spacing[12]};
  
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 1.25rem;
  }
`;