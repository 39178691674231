import {useParams} from "react-router-dom";
import {useMemo} from "react";

export function useSessionListenUrl() {
    const { sessionName } = useParams<{ sessionName: string }>();

    return useMemo(() =>
            `${process.env.REACT_APP_APP_URL}/${sessionName}`,
        [sessionName],
    );
}
