import {useAuth} from "../../../context";
import {Navigate} from "react-router-dom";
import {PropsWithChildren} from "react";

export function NonProtectedRoute({ children }: PropsWithChildren) {
    const { isAuthenticated } = useAuth();

    if (isAuthenticated) {
        return <Navigate to="/panel" />;
    }

    return <>{children}</>;
}
