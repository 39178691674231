import styled from "styled-components";

export const Button = styled.button<{
    fullWidth?: boolean,
    variant?: 'default' | 'secondary',
}>`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-family: "Roboto Mono", monospace;
    font-size: 12px;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    border: none;
    background-color: ${({ variant }) => variant === 'secondary' ? '#ececf1' : '#fff'};
    color: #101010;
    border-radius: 1000px;
    padding: 8px 24px;
    min-height: 32px;
    line-height: 32px;
    transition: transform 0.1s ease-in-out, background-color 0.1s ease-in-out;
    outline: none;
    cursor: pointer;
    width: ${({ fullWidth }) => fullWidth ? '' : 'fit-content'};
    
    &:hover {
        background-color: #d8d8d8;
    }
`;

const Icon = styled.span`
    display: flex;
`;

export const IconStart = styled(Icon)<{ size?: 'medium' }>`
    margin-left: -8px;
    
    svg {
        width: ${({ size }) => size === 'medium' ? '24px' : '16px'};
        height: ${({ size }) => size === 'medium' ? '24px' : '16px'};
    }
`;
