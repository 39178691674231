import styled from "styled-components";

export const Actions = styled.div`
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #e7e7e7;
    padding-top: 24px;
`;
