export class Playback {
    private queue: string[] = [];
    private isPlaying = false;
    private audio: HTMLAudioElement | undefined;

    /**
     * Initialize the audio player on user action
     * Otherwise, the browser on mobile will block the audio from playing
     */
    init() {
        if (this.audio) {
            this.destroy();
        }

        this.audio = new Audio();
        this.audio.addEventListener('ended', this.playNextInQueue.bind(this));
    }

    async add(audio: ArrayBuffer) {
        const blob = new Blob([audio], { type: 'audio/mp3' });
        const url = URL.createObjectURL(blob);
        this.queue.push(url);

        if (!this.isPlaying && this.audio) {
            this.isPlaying = true;
            await this.playNextInQueue();
        }
    }

    private async playNextInQueue() {
        if (this.queue.length > 0 && this.audio) {
            const nextAudio = this.queue.shift();

            console.log({ queue: this.queue });
            if (nextAudio) {
                this.audio.src = nextAudio;
                this.audio.play().catch(console.error);
            } else {
                this.isPlaying = false;
            }
        } else {
            this.isPlaying = false;
        }
    }

    private destroy() {
        if (this.audio) {
            this.audio.removeEventListener('ended', this.playNextInQueue.bind(this));
            this.audio.remove();
            this.audio = undefined;
        }
    }
}
